import axios from "axios";

export async function getSession({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
        await axios({
            method: 'POST',
            url: "https://www.desertlinktours.com/api/login",
            data: {
                Agtid: payload.Agtid,
                AgtPwd: payload.AgtPwd
            }
        }).then((response) => {

            localStorage.setItem('access_token', response.data.token)
            localStorage.setItem("agent", JSON.stringify(response.data.user))
            localStorage.setItem("isLogged", true)
            commit("setLogin", response.data)
            resolve(response.data)

        }).catch((error) => {
            console.debug(error)
            reject(error)
        })
    })
}

export async function signout({ commit }, payload) {
    return axios.post("https://www.desertlinktours.com/api/signout", payload)
}
