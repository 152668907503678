<template>
  <div class="home">
    <!-- <navbar /> -->
    <carousel />
    <login class="loginContent" v-if="!isLogged" />
    <!-- <about style="height: 90vh" /> -->
    <!-- <services /> -->
    <footer-home />
  </div>
</template>

<script>

import Carousel from "../components/Carousel.vue";
import About from "./About.vue";
import Services from "./Services.vue";
import FooterHome from "../components/FooterHome.vue";
import Login from './Login.vue';

export default {
  name: "Home",
  data() {
    return {

    }
    
  },
  computed: {
    isLogged: function() {
      if(localStorage.getItem('isLogged')) {
        return true
      } else { return false }
    }
  },
  components: {
    // Navbar,
    Carousel,
    About,
    Services,
    FooterHome,
    Login,
  },
};
</script>

<style lang="scss" scoped>
body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.home {
  background: url('../assets/backgroundColor.png');
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.loginContent {
  position: absolute;
  top: 20%;
  z-index: 1;
}
</style>
