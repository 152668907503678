<template>
  <div id="app">
    <navbar :key="updateNavbar" />
    <transition name="fade">
      <router-view :guestData="guestData" :hotelsData="data"></router-view>
    </transition>
  </div>
</template>
<script>
import Home from "./pages/Home.vue";
import Navbar from "./components/Navbar.vue"
import { $bus } from "./eventBus";

export default {
  data() {
    return {
      forceUpdate: 0,
      data: [0],
      guestData: null,
      allGuestInfo: null,
      updateNavbar: 0
    };
  },
  components: {
    Home,
    Navbar
  },
  created() {
    document.title = "Desert Link Tours";    
    $bus.$on('updateNavbar', this.setNavbar)
    $bus.$on('updateGuestData', this.setGuestData)
  },
  methods: {

    setNavbar() {
			this.updateNavbar += 1
		},

    setGuestData(item) {
      this.guestData = item
    }
    
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

html {
  height: 100%;
  width: 100%;
	// background: url('./assets/backgroundColor.png');
}

#app {
	// background: url('./assets/backgroundColor.png');
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.spinner {
  display: block;
  position: fixed;
  z-index: 1031; /* High z-index so it is on top of the page */
  top: 50%;
  right: 50%; /* or: left: 50%; */
}

body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.back {
  height: 30px;
  cursor: pointer;
  margin-bottom: 20px;
}
.back:hover {
  -webkit-box-shadow: 0px 3px 9px 0px rgb(231, 231, 231);
  -moz-box-shadow: 0px 3px 9px 0px rgb(236, 236, 236);
  box-shadow: 0px 3px 9px 0px rgb(238, 238, 238);
}

.back:focus {
  outline: none !important;
}
</style>
