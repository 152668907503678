import axios from "axios";

export async function balance({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
        await axios.post('https://www.desertlinktours.com/api/balance', {
            company: payload.company,
            agentid: payload.agentid,
            current: payload.current,
            balance: payload.balance,
            token: payload.token
        }
        ).then((response) => {
            resolve(response.data)
        }).catch((error) => reject(error))
    })
}

export async function balancehistory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
        var data = JSON.stringify({
            "agentid": payload.agentid
        });
        var config = {
            method: 'post',
            url: 'https://www.desertlinktours.com/api/balancehistory',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        await axios(config).then((response) => {
            resolve(response.data.data)
        }).catch((error) => reject(error))
    })
}