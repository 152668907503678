export function setPackages(state, payload) {
     state.packageData = payload
}



export function setPackagesByParentID(state, payload) {
     state.packageByParentId = payload
}


export function setPackagesByID(state, payload) {
     state.packageByID = payload
}

export function setPackagesByCategory(state, payload) {
     state.packageByCategory = payload
}


export function setpID(state, payload) {
     state.pID = payload.pID
     state.pName = payload.pName
}

