export function setCart(state, payload) {
   state.cartItems.push(payload)
}

export function updateCart(state, payload) {
   for (let i = 0; i < state.cartItems.length; i++) {
      if (state.cartItems[i].id === payload.id) {
         var index = state.cartItems.indexOf(state.cartItems[i])
         return state.cartItems.splice(index, 1)
      }
   }
}

export function setEmptyCart(state) {
   state.cartItems = []
}