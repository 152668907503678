<template>
  <div>
    <navbar v-if="$route.matched.some(({ name }) => name === 'Services')" />
    <div class="servicesWrap">
      <div class="container">
        <div class="row">
          <div class="col-md-12" style="display: flex; justify-content: center">
            <h1>Services</h1>
            <div class="line"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="box"
              v-for="(tab, index) in tabs_1"
              @click="$router.push(tab.link)"
              :key="index"
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
              "
            >
              <p>{{ tab.title }}</p>
            </div>
          </div>

          <div class="col-md-6">
            <div
              class="box"
              v-for="(tab, index) in tabs_2"
              :key="index"
              @click="$router.push(tab.link)"
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
              "
            >
              <p>{{ tab.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-home
      v-if="$route.matched.some(({ name }) => name === 'Services')"
    />
  </div>
</template>
<script>
import FooterHome from "../components/FooterHome.vue";
import Navbar from "../components/Navbar.vue";

export default {
  data() {
    return {
      tabs_1: [
        { title: "Visa", link: "/umrah/visains" },
        { title: "Local Sightseeing", link: "/packages" },
        { title: "MICE", link: "/packages" },
        { title: "Honeymoon Packages", link: "/packages" },
        { title: "Weekend Breaks ", link: "/packages" },
      ],
      tabs_2: [
        { title: "Scuba Diving Packages", link: "/rental/ScoobaDiving" },
        { title: "Yacht Packages", link: "/rental/Yacht" },
        { title: "Stretch Limousine", link: "/rental/StretchLimo" },
        { title: "Umrah Packages", link: "/umrah/upackage" },
      ],
    };
  },
  components: { Navbar, FooterHome },
};
</script>
<style lang="scss" scoped>
.servicesWrap {
  //height: 100vh;
  width: 100%;
  padding: 5% 0;
  // margin: 5% 0 0 0;
  position: relative;
  background: url("../assets/backgroundColor.png");

  .box {
    margin: 8% 0 0 0;
    border-radius: 6px;
    text-align: center;
    padding: 20px;
    background-color: white;
    height: 120px;
    -webkit-box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
    -moz-box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
    box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
    font-weight: 200;
    cursor: pointer;
  }

  .line {
    height: 1px;
    width: 25%;
    background: #ff6f00;
    position: absolute;
    // opacity: 0.3;
    top: 13%;
    // left: 40%;
  }

  .box:hover {
    background: #212121;
    color: white;
    transition: all 500ms ease-in-out;
  }
}

@media (min-width: 300px) and (max-width: 700px) {
  .servicesWrap {
    margin-top: 200%;
  }
}
</style>
