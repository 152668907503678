export function setRentals(state, payload) {
    state.rentals = payload
}

export function setRentalsByPriority(state, payload) {
    state.rentalsByPriority = payload
}

export function setRentalsByParentId(state, payload) {
    state.rentalsByParentId = payload
}