import axios from 'axios'
export async function saveCart({ commit }, payload) {
	console.log(payload)
    commit('setCart', payload)
}

export async function deleteCart({ commit }, payload) {
    commit('updateCart', payload)
}

export async function checkoutCart({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
        try {
            await axios.post('https://www.desertlinktours.com/api/checkout', payload).then((response) => {
                if (response) {
                    let res = response.data
                    resolve(res)
                }
            }).catch((error) => {
                console.debug(error)
            })
        } catch (error) {
            reject(error)
        }

    })

}

export async function emptyCart({ commit }) {
    commit('setEmptyCart')
}