export function allpackages (state) {
     return state.packageData;
}

export function packagesbyid (state) {
     return state.packageByID
}

export function packagesbycategory (state) {
     return state.packageByCategory
}

