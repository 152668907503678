var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$route.matched.some(function (ref) {
	var name = ref.name;

	return name === 'Services';
}))?_c('navbar'):_vm._e(),_c('div',{staticClass:"servicesWrap"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},_vm._l((_vm.tabs_1),function(tab,index){return _c('div',{key:index,staticClass:"box",staticStyle:{"display":"flex","flex-direction":"column","justify-content":"center"},on:{"click":function($event){return _vm.$router.push(tab.link)}}},[_c('p',[_vm._v(_vm._s(tab.title))])])}),0),_c('div',{staticClass:"col-md-6"},_vm._l((_vm.tabs_2),function(tab,index){return _c('div',{key:index,staticClass:"box",staticStyle:{"display":"flex","flex-direction":"column","justify-content":"center"},on:{"click":function($event){return _vm.$router.push(tab.link)}}},[_c('p',[_vm._v(_vm._s(tab.title))])])}),0)])])]),(_vm.$route.matched.some(function (ref) {
	var name = ref.name;

	return name === 'Services';
}))?_c('footer-home'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12",staticStyle:{"display":"flex","justify-content":"center"}},[_c('h1',[_vm._v("Services")]),_c('div',{staticClass:"line"})])])}]

export { render, staticRenderFns }