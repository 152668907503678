import Vue from 'vue'
import Vuex from 'vuex'
import packages from './packages/index'
import login from './login/index'
import register from "./register/index"
import cart from "./cart/index"
import rentals from "./rentals/index"
import createPersistedState from 'vuex-persistedstate'
import psgdetails from './psgdetails/index'
import account from './account/index'
import LogRocket from 'logrocket'
import createPlugin from 'logrocket-vuex';

const logrocketPlugin = createPlugin(LogRocket);

Vue.use(Vuex)
export default function () {
  const store = new Vuex.Store({
    plugins: [createPersistedState({
      storage: window.sessionStorage,
    }), logrocketPlugin],
    modules: {
      packages,
      login,
      register,
      cart,
      rentals,
      psgdetails,
      account
    },
  })
  return store
}