<template>
	<div class="navbarContainer">
		<div class="container">
			<div class="row">
				<div class="col-md-2 col-lg-2 col-xs-2 logo-container">
					<img src="../assets/logo.svg" class="logo" @click="$router.push('/')" />
				</div>
				<div class="col-xs-8 col-md-8 col-lg-8">
					<ul class="nav_ul">
						<li>
							<router-link to="/" class="navlink">Home</router-link>
						</li>
						<li v-if="isLogged">
							<router-link to="/packages" class="navlink" exact>Tours</router-link>
						</li>
						<li v-if="isLogged">
							<router-link to="/rentals_lp" class="navlink" exact>Rentals</router-link>
						</li>
						<li v-if="isLogged">
							<router-link to="/hotels_landing" class="navlink" exact>Hotels</router-link>
						</li>
						<li>
							<router-link to="/umrah/upackage" class="navlink" exact>Umrah</router-link>
						</li>

						<!-- <li>
							<div class="dropdown">
								<button class="dropbtn">Umrah</button>
								<div class="dropdown-content">
									<router-link to="/umrah/upackage" exact>Umrah Packages</router-link>
								</div>
							</div>
						</li> -->
						<li>
							<router-link to="/visains" class="navlink" exact>Visa</router-link>
						</li>
						<li v-if="isLogged && user.isAdmin">
							<router-link to="/adminpanel" class="navlink" exact>Admin Panel</router-link>
						</li>
					</ul>
				</div>
				<div class="col-xs-2 col-md-2 col-lg-2">
					<router-link to="/" v-if="!isLogged">
						<b-button class="login">Login</b-button>
					</router-link>
					<router-link to="/register" v-if="!isLogged">
						<b-button class="signup">Signup</b-button>
					</router-link>
					<img v-if="isLogged" @click="$router.push('/cart')" src="../assets/cart.svg" class="icon" ref="cart" />
					<img v-if="isLogged" @click="$router.push('/userDashboard')" src="../assets/user.svg" class="icon"
						ref="user" />
					<img v-if="isLogged" @click="signout()" src="../assets/signout.svg" class="icon" ref="signout" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Navbar",
	data() {
		return {
			isLogged: localStorage.isLogged,
			user: {},
			isMobile: false,
		};
	},
	mounted() {
		this.isMobile = window.innerWidth > 767 ? false : true
		this.user = JSON.parse(localStorage.getItem('agent'))
	},
	methods: {
		signout() {
			if (this.isLogged) {
				const user = JSON.parse(localStorage.getItem("agent"));
				this.$store.dispatch("login/signout", user["Agtid"]);
				localStorage.clear();
				this.$store.dispatch("cart/emptyCart");
				this.$store.dispatch("cart/emptyPsg");
				this.$session.destroy();
				window.sessionStorage.setItem("isLogged", false);
				if (this.$route.name != "/") {
					this.$router.replace("/").catch((error) => {});
					this.$router.go();
				}
				this.$router.push("/").catch((error) => {});
				this.$router.go();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.navbarContainer {
	z-index: 2;
	// background: white;
	background: rgba(255, 255, 255, 0);
	/* From https://css.glass */
	background: rgba(255, 255, 255, 0.79);
	// border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	border: 1px solid rgba(255, 255, 255, 0.3);
	position: sticky;
	top: 0;
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	// position: relative;
	// top: 0;
	padding: 10px 0;

	// border: none;
	// -webkit-box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
	// -moz-box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
	// box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
	flex-wrap: nowrap;
}

.logo {
	height: 40px;
	width: 200px;
}

.logo-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.nav_ul {
	display: flex;
	justify-content: center;
	flex-direction: row;
	position: relative;

	li {
		list-style: none;

		.dropbtn {
			// background-color: #fff;
			color: #212121;
			position: relative;
			padding: 10px 20px 10px 20px;
			// margin-top: 2px;
			top: 5px;
			border: none;
			background: transparent;
			font-size: 16px;
			font-weight: 600;
			border: none;
			cursor: pointer;
		}

		.dropdown {
			position: relative;
			display: inline-block;
		}

		/* Dropdown Content (Hidden by Default) */
		.dropdown-content {
			display: none;
			// margin-top: 10px;
			position: absolute;
			background-color: #f9f9f9;
			min-width: 200px;
			box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
			z-index: 1;
		}

		/* Links inside the dropdown */
		.dropdown-content a {
			color: black;
			padding: 12px 16px;
			text-decoration: none;
			display: block;
		}

		/* Change color of dropdown links on hover */
		.dropdown-content a:hover {
			// width:100%;
			// background-color: #f1f1f1;
			background: #212121;
			color: #fff;
			transition: all 500ms ease-in-out;
		}

		/* Show the dropdown menu on hover */
		.dropdown:hover .dropdown-content {
			display: block;
		}

		/* Change the background color of the dropdown button when the dropdown content is shown */
		.dropdown:hover .dropbtn {
			// background-color: #3e8e41;
			background: #f1f1f1;
		}

		.navlink {
			display: flex;
			flex-direction: row;
			margin: 0 10px;
			position: relative;
			top: 30%;
			padding: 2px 20px;
			border-radius: 4px;
			// color: #90d9fd;
			color: #212121;
			font-weight: 600;
			border: none;
			transition: all 600ms ease-in-out;
			cursor: pointer;
		}
		.navlink:hover {
			transition: all 600ms ease-in-out;
			color: #26A69A;
		}
	}
}

.icon {
	width: 20px;
	height: 20px;
	position: relative;
	top: 15%;
	margin: 0 20px;
	cursor: pointer;
	fill: white!important;

	@media (min-width: 1000px) {
		margin: 0 10px;
	}
}

a {
	color: black;
	text-decoration: none;
}

.login,
.signup {
	background: white;
	color: black;
	border: 1px solid #212121;
	font-weight: 300;
	position: relative;
	top: 10%;
	padding: 5px 20px;
	margin: 0 5px;
	-webkit-box-shadow: 0px 0px 3px 0px rgba(220, 220, 220, 1);
	-moz-box-shadow: 0px 0px 3px 0px rgba(220, 220, 220, 1);
	box-shadow: 0px 0px 3px 0px rgba(220, 220, 220, 1);

	@media (max-width: 767px) {
		margin: 10px 0;
	}
}
.login:hover,
.signup:hover {
	background: black !important;
	color: white;
	// border: 1px solid white;
}

.login:active,
.login:focus {
	outline: none;
}

.signout {
	background: white;
	color: black;
	border: 0.5px solid black;
	font-weight: 300;
	margin: 0 20px 0 0;
	outline: none !important;

	@media (max-width: 767px) {
		margin: 10px 0;
	}
}

.signout:active,
.signout:focus {
	outline: none !important;
}

.signout:hover {
	background: black !important;
	color: white;
	border: 1px solid white;
}
</style>
