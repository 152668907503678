import axios from "axios";

export async function register({ commit }, payload) {
  return commit("setRegister", payload);
}

export async function postRegister({ commit }, payload) {
  return new Promise((resolve, reject) => {
    axios
      .post("https://www.desertlinktours.com/api/register", {
        Agtid: null,
        IsLogged: 0,
        AgtPwd: null,
        AgtName: payload.legalname,
        CmpName: payload.companyname,
        AgtAdd: payload.address, // Agent Address
        AgtNo: parseFloat(payload.mobilenumber), // Agent Contact Number
        AgtEmail: payload.emailaddr, // Agent Email
        LcnNO: parseFloat(payload.licenseNo), // License Number
        LcExp: parseFloat(payload.licenseExpiry), // License Expiry
        POBox: parseFloat(payload.pobox), // POBOX
        ZipCode: null, // ZIPCODE
        AgtNtnlAdd: payload.naddr, // NATIONAL ADDRESS
        City: payload.city, // CITY
        Country: payload.country, // COUNTRY
        WebbAdd: payload.website, // WEBSITE ADDRESS
        OwnerName: payload.ownername, // OWNER NAME
        OwnerNo: parseFloat(payload.ownernumber), // OWNER NO
        OwnerEmail: payload.OwnerEmail, // OWNER EMAIL
        TourDeptHeadName: payload.td_headname, // TOUR DEPARTMENT HEAD NAME
        TourDeptHeadNo: parseFloat(payload.td_headnumber), // TOUR DEPARTMENT HEAD NUMBER
        TourDeptHeadEmail: payload.pay_heademail, // TOUR DEPARTMENT HEAD EMAIL
        FinanceHeadName: payload.fn_headname, // FINANCE HEAD NAME
        FinanceHeadNo: parseFloat(payload.fn_headnumber), // FINANCE HEAD NUMBER
        FinanceHeadEmail: payload.fn_heademail, // FINANCE HEAD EMAIL
        PaymentDeptHeadName: payload.pay_headname, // PAYMENT DEPT HEAD NAME
        PaymentDeptHeadNo: parseFloat(payload.pay_headnumber), // PAYMENT DEPT HEAD NO
        PaymentDeptHeadEmail: payload.pay_heademail, // PAYMENT DEPT HEAD EMAIL
        SecondaryNo: payload.second_contact, // ALTERNATE CONTACT NUMBER
        CmpID: "CMP" + payload.licenseNo,
        CmpBalance: "",
        isOwner: parseFloat(payload.isOwner),
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
