import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "./routes";
import { isLoggedIn } from '../helpers/helper'

Vue.use(VueRouter);

const router = new VueRouter({
   mode: "history",
   base: process.env.BASE_URL,
   routes,
});

router.beforeEach((to, from, next) => {
   const isLogged = isLoggedIn()

   // if (to.matched.some(route => route.meta.auth)) {
   //    if (isLogged) {
   //       next()
   //    }
   // }

   // if (to.matched.some(route => route.meta.auth === false)) {
   //    return router.push('/').catch((error) => { })
   // }

   if (to.matched.some(record => record.meta.auth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!isLogged && to.name !== 'login') {
         next({
            path: '/login',
            replace: true,
            query: { redirect: to.fullPath }
         })
      } else {
         next()
      }
   } else {
      next() // make sure to always call next()!
   }

})
export default router;
