
export const routes = [
   {
      path: "/",
      name: "home",
		component: () => import("../pages/Home.vue"),
      meta: { auth: false }
   },
   {
      path: "/dashboard/:id",
      name: "Dashboard",
		component: () => import("../pages/Home.vue"),
      meta: { auth: true }
   },
   {
      path: "/packages",
      name: "packages",
		component: () => import("../pages/Packages.vue"),
      meta: { auth: true }
   },
   {
      path: "/packageDetails/:id",
      name: "packageDetails",
		component: () => import("../pages/PackageDetails.vue"),
      meta: { auth: true }
   },
   {
      path: "/register",
      name: "Register",
		component: () => import("../pages/Register.vue"),

   },
   {
      path: "/registernext",
      name: "RegisterNext",
		component: () => import("../pages/RegisterNext.vue"),

   },
   {

      path: "/passenger/:index",
      name: "Passenger",
		component: () => import("../pages/passenger.vue"),
      meta: { auth: true }

   },
   {
      path: "/rental/:name",
      name: "Rental",
		component: () => import("../pages/Rental.vue"),
      meta: { auth: true }
   },
   {
      path: "/login",
      name: "Login",
		component: () => import("../pages/Login.vue"),

   },
   {
      path: "*",
		component: () => import("../pages/404.vue")
   },
   {
      path: "/cart",
      name: "Cart",
		component: () => import("../pages/Cart.vue"),
      meta: { auth: true }
   },
   {
      path: "/accountssec",
      name: "AccountsSec",
		component: () => import("../pages/AccountsSec.vue"),
      meta: { auth: true }
   },
   {
      path: "/terms",
      name: "Terms",
		component: () => import('../pages/Terms.vue')
   },
   {
      path: "/privacy",
      name: "Privacy",
		component: () => import('../pages/Privacy.vue')
   },
   {
      path: "/userDashboard",
      name: "userDashboard",
		component: () => import('../admin/UserProfile.vue'),
      meta: { auth: true }
   },
   {
      path: "/adminpanel",
      name: "adminPanel",
		component: () => import('../components/Admin/AdminPanel.vue'),
      meta: { auth: true }
   },
   {
      path: "/umrah/upackage",
      name: "Umrah",
		component: () => import('../pages/Umrah/UPackage.vue'),
      // meta: { auth: true }
   },

   {
      path: "/visains",
      name: "VisaIns",
		component: () => import('../pages/Umrah/VisaIns.vue'),
      meta: { auth: true }
   },

   {
      path: "/rentals_lp",
      name: "Rentals Landing",
		component: () => import('../pages/Rental_LP.vue'),
      meta: { auth: true }
   },

   {
      path: "/hotels_landing",
      name: "Hotels Landing",
		component: () => import('../pages/HotelsLanding.vue'),
      meta: { auth: true }
   },

   {
      path: "/hotels",
      name: "HotelResults",
      props: true,
      component: () => import("../pages/HotelResults.vue"),
   },
   {
      path: "/hotels/:name",
      name: "HotelDetails",
      props: true,
      component: () => import("../pages/HotelDetails.vue"),
   },

   {
      path: "/reservation",
      name: "Reservation",
      props: true,
      component: () => import("../pages/Reservation.vue"),
   },

   {
      path: "/payment",
      name: "Payment",
      props: true,
      component: () => import("../pages/Payment.vue"),
   },

   {
      path: "/booking/:no",
      name: "Booking",
      props: true,
      component: () => import("../pages/Booking.vue"),
   },

   {
      path: "/admin",
      name: "Admin",
      props: true,
      component: () => import("../admin/HotelAdmin.vue"),
   },

   {
      path: "/upload",
      name: "Upload",
      props: true,
      component: () => import("../pages/Upload.vue"),
   },

]
