import Vue from 'vue'

const WebSql=function(){
    if(window.openDatabase){
        //openDatabase('Database name','version','database description','database size')
        var db = openDatabase('TC','1.0','taicang',200*1024*1024);
       
        Vue.prototype.$db=db;
        
        if(!db){
            console.log("Database creation failed!");
        }else{
            console.log('The local database was created successfully!');
        }
    }else{
        console.log('Local storage is not supported!');
    }
};
export default WebSql;