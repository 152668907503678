<template>
<div class="footer" style="border-top: 1px solid #FFC107;">
  <div class="container">
    <div class="row pt-5 pb-5">
      <div class="col-md-4 col-xs-12 right-border" >
        <h5>INFORMATION</h5>
        <div class="list">
          <ul class="list-unstyled">
            <li><router-link class="link" to="/about">About Us</router-link></li>
            <li><router-link class="link" to="/privacy">Privacy Policy</router-link></li>
            <li><router-link class="link" to="/terms">Terms & Conditions</router-link></li>
          </ul>
        </div>
      </div>
      <div class="col-md-4 col-xs-12 right-border">
        <h5>CUSTOMER SUPPORT</h5>
        <div class="list">
          <ul class="list-unstyled">
            <li><router-link class="link" to="/">FAQ</router-link></li>
            <li><router-link class="link" to="/">Payment</router-link></li>
          </ul>
        </div>
      </div>
      <div class="col-md-4 col-xs-12 location">
        <h5>Location</h5>
        <div>Office 901, Ontario Tower, Bussiness Bay, Burj Khalifa District, Dubai, UAE</div>
      </div>
    </div>
    <div class="row copyright">
      <div class="col-xs-12 col-md-12">
        <p>© 2021 DESERT LINK TOURISM LLC</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default{
    name: "FooterHome"
}
</script>


<style lang="scss" scoped>

.footer {
  background: #FAFAFA;
}

h5 {
  text-align: center;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
}

.list-unstyled {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.right-border {
  border-right: 1px solid #FFC107;
  color: #212121;
  // padding: 10px 0 20px 0;
}

.link {
  cursor: pointer;
  transition: all 500ms ease-in-out;
  color: #212121;
  text-decoration: none;
}

.link:hover {
  font-weight: 600;
  transition: all 500ms ease-in-out;
  color: #26A69A;
}

.copyright {
  text-align: center;
  padding: 20px 0;
  border-top: 1px solid #FFC107;
  border-left: 1px solid #FFC107;

  p {
    text-transform: uppercase;
  }
}

.location {
  div {
    text-align: center;
  }
  
  h5 {
    text-transform: uppercase;
  }
}



</style>