<template>
  <div class="login">
    <div class="container login__container">
      <div class="login__back" @click="$router.push({ path: '/' })">
        <img src="@/assets/back.svg" />
      </div>
      <div class="login__header">
        <span>Login</span>
      </div>
      <b-form @submit.prevent="submit" class="login__form">
        <div class="form-group">
          <label for="agentid">Agent ID</label>
          <input
            type="number"
            class="form-control input-focus"
            name="agentid"
            id="agentid"
            placeholder="Enter your Agent ID"
            v-model.lazy="loginDetails.agentid"
          />
        </div>
        <div class="form-group">
          <label for="agentid">Password</label>
          <input
            type="password"
            class="form-control input-focus"
            name="password"
            id="password"
            placeholder="Enter your Password"
            v-model.lazy="loginDetails.password"
          />
        </div>
        <div class="form-group login__submit">
          <b-button class="submit pb2" type="submit">Login</b-button>
        </div>
        <div class="form-group login__error" v-if="error">
          <span class="text-danger">{{ error }}</span>
        </div>
        <div class="form-group login__loading" v-if="loading">
          <b-spinner variant="dark" type="grow" v-if="loading"></b-spinner>
        </div>
      </b-form>
    </div>
  </div>
</template>
<script>
import LogRocket from "logrocket";
import { $bus } from '@/eventBus.js'
export default {
  name: "Login",
  data() {
    return {
      loginDetails: {
        agentid: "",
        password: "",
      },
      error: "",
      loading: false
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      if (!this.loginDetails.agentid && !this.loginDetails.password) {
        this.loading = false;
        return (this.error = "Username and Password is required");
      }
      let payload = {
        Agtid: this.loginDetails.agentid,
        AgtPwd: this.loginDetails.password,
      };
      this.$store
        .dispatch("login/getSession", payload)
        .then((response) => {
          if (response.status === 200 && response.token) {
            console.log(response)
            setTimeout(() => {
              this.loading = false;
              if(window.sessionStorage) {
                window.sessionStorage.setItem("isLogged", true);
              }
              this.$session.start();
              this.$session.set("access_token", response.token);
              if(window.localStorage) {
                localStorage.setItem('isLogged', true)
              }
              LogRocket.identify(this.loginDetails.agentid, {
                name: response.user.AgtName,
                email: response.user.AgtEmail,
                token: response.token,
                balance: response.user.CmpBalance,
                isAdmin: response.user.isAdmin,
                isVerified: response.user.isVerified,
                isLogged: response.user.isLogged
              });
              $bus.$emit('updateNavbar')
              this.$router.push("/packages");
            }, 1200);
          } else {
            this.loading = false;
            return (this.error = response.message);
          }
        })
        .catch((error) => {
          console.debug(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>

.login {
  // background: #FFF;
  padding: 3rem 1rem;
  height: 100vh;
  // width: 30em;
  width: 100%;
  display: flex;
  justify-content: center;
  // border: 1px solid #212121;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border-radius: 8px;

  &__container {
    width: 30rem;
    height: 32rem;
    background: white;
    // border: 1px solid #212121;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    padding: 5rem 3rem;
  }

  &__back {
    cursor: pointer;

    img {
      width: 30px;
      height: 30px;

      &:hover {
        color: white;
      }
    }

  }

  &__header {
    display: flex;
    justify-content: center;
    font-family: "Open Sans", sans-serif;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 600;
    color: #212121;
  }

  &__loading {
    display: flex;
    align-items: center;
  }

  &__error {

    span {
      display: flex;
      justify-content: center;
      text-transform: uppercase;
    }
    
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .form-group {
      margin: .75rem 0;
      display: flex;
      justify-content: space-between;
      padding: 5px 0;
      flex-direction: column;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;

      label {
        color: #212121;
      }

      input {
        background: transparent;
        border: none;
        border-bottom: 2px solid #607D8B;
        outline: none;
        border-radius: 0;
        padding: 10px 0;

        &::placeholder {
          color: #212121;
          font-weight: 300;
        }

        &:focus {
          outline:none !important;
          outline-width: 0 !important;
          box-shadow: none;
          -moz-box-shadow: none;
          -webkit-box-shadow: none;
          border-bottom-color: #FFD54F;
          transition: all 300ms ease-in-out;
        }

        &:hover {
          border-bottom-color: #FFD54F;
          transition: all 300ms ease-in-out;
        }

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button { 
          -webkit-appearance: none;
        }

      }
    }
  }

  &__submit {
    display: flex;
    justify-content: center;


    button {
      align-items: center;
      background-color: initial;
      background-image: linear-gradient(#464d55, #25292e);
      border-radius: 8px;
      border-width: 0;
      box-shadow: 0 10px 20px rgba(0, 0, 0, .1),0 3px 6px rgba(0, 0, 0, .05);
      box-sizing: border-box;
      color: #fff;
      cursor: pointer;
      display: inline-flex;
      flex-direction: column;
      font-family: "Open Sans", sans-serif;
      text-transform: uppercase;
      font-size: 18px;
      padding: 12px 3rem;
      justify-content: center;
      line-height: 1;
      margin: 0;
      outline: none;
      overflow: hidden;
      text-align: center;
      text-decoration: none;
      transform: translate3d(0, 0, 0);
      transition: all 150ms;
      vertical-align: baseline;
      white-space: nowrap;

      &:hover {
        box-shadow: rgba(0, 1, 0, .2) 0 2px 8px;
        opacity: .85;
      }

      &:active {
        outline: 0;
      }

      &:focus {
        box-shadow: rgba(0, 0, 0, .5) 0 0 0 3px;
      }
    }
  }
}

</style>
