import axios from 'axios'

export async function getPackagesByPri({ commit }) {
   return new Promise(async (resolve, reject) => {
      await axios.get("https://www.desertlinktours.com/api/packagesbyCat").then((response) => {
         commit("setPackages", response.data)
         resolve(response.data)
      }).catch((error) => {
         reject(error)
         console.debug(error)
      })
   })
}

export async function getPackages({ commit }) {
   return new Promise(async (resolve, reject) => {
      await axios.get("https://www.desertlinktours.com/api/packages").then((response) => {
         commit("setPackages", response.data)
         resolve(response.data)
      }).catch((error) => {
         reject(error)
         console.debug(error)
      })
   })
}

export async function getPackagesByParentId({ commit }, payload) {
   return new Promise(async (resolve, reject) => {
		await axios.post("https://www.desertlinktours.com/api/packagesbyParentId/", { parentid: payload} ).then((response) => {
         commit("setPackagesByParentID", response.data)
         resolve(response.data)
      }).catch((error) => {
         reject(error)
         console.log(error)
      })
   })
}

export async function getPackagesByCategory({ commit }, category) {
   await axios.get("https://www.desertlinktours.com/api/packages").then((response) => {
      const res = response.data
      const obj = new Array()
      for (let i = 0; i < res.length; i++) {
         if (res[i].PckgSub === category) {
            obj.push(res[i])
         }
      }
      commit("setPackagesByCategory", obj)
      return
   }).catch((error) => console.log(error))
}


export async function getpID({ commit }, payload) {

   commit("setpID", payload)

}


