import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Vuelidate from 'vuelidate'
import store from "./store/index";
import _ from "lodash"
import VueSession from 'vue-session'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import html2pdf from "html2pdf.js";
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'
import WebSql from './WebSql/WebSql'
import JsonExcel from 'vue-json-excel'
import LogRocket from 'logrocket';

LogRocket.init('i0lrv6/desert-link-tourism');
//Introduce send request
import VueResource from 'vue-resource'
Vue.use(VueResource);
Vue.component('downloadExcel', JsonExcel)
Vue.use(WebSql);
Vue.use(ElementUI, { locale });
Vue.use(Vuelidate);
Vue.use(html2pdf);


Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuelidate)
Vue.use(_)
Vue.use(VueSession)
Vue.use(Autocomplete)

Vue.prototype.$bus = new Vue();



new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    if (!this.$session.exists()) {
      localStorage.clear()
    }
  }
}).$mount("#app");