export async function setPassengers({ commit }, payload) {
   commit('setPassengersDetails', payload)
}

export async function updatePassengers({ commit }, payload) {
   commit('removePassengersDetails', payload)
}

export async function emptyPsg({ commit }) {
   commit('setPsgEmpty')
}