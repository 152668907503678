import axios from 'axios'


export async function getRentals({ commit }) {
   return new Promise(async (resolve, reject) => {
      await axios.get("https://www.desertlinktours.com/api/rentals").then((response) => {
         commit("setRentals", response.data)
         resolve(response.data)
      }).catch((error) => {
         reject(error)
      })
   })
}

export async function getRentalsByName({ commit }, payload) {
   return new Promise(async (resolve, reject) => {
      await axios.post("https://www.desertlinktours.com/api/rentalsByName", {
         "rentalName": payload
      }).then((response) => {
         commit("setRentals", response.data)
         resolve(response.data)
      }).catch((error) => {
         reject(error)
      })
   })
}



export async function getRentalsByPriority({ commit }) {
   return new Promise(async (resolve, reject) => {
      await axios.get("https://www.desertlinktours.com/api/rentalsbypriority").then((response) => {
         commit("setRentalsByPriority", response.data)
         resolve(response.data)
      }).catch((error) => {
         reject(error)
      })
   })
}


export async function getRentalsByParentId({ commit }, payload) {
   return new Promise(async (resolve, reject) => {
      await axios.post("https://www.desertlinktours.com/api/rentalsbyparentid", {
         rparentid: payload
      }).then((response) => {
         commit("setRentalsByParentId", response.data)
         resolve(response.data)
      }).catch((error) => {
         reject(error)
      })
   })
}
