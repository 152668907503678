<template>
	<div class="c-container">
		<b-carousel id="carousel-fade" :interval="4000" indicators fade dark @sliding-start="onSlideStart"
			@sliding-end="onSlideEnd">
			<b-carousel-slide :img-src="require('../assets/bg1.jpeg')" class="slideimg">

			</b-carousel-slide>
			<b-carousel-slide :img-src="require('../assets/bg2.jpg')" class="slideimg">

			</b-carousel-slide>
			<b-carousel-slide :img-src="require('../assets/bg3.jpg')" class="slideimg">

			</b-carousel-slide>
			<b-carousel-slide :img-src="require('../assets/bg4.jpg')" class="slideimg">


			</b-carousel-slide>
		</b-carousel>
	</div>

</template>
<script>
export default {
	data() {
		return {
			slide: 0,
			sliding: null,
		};
	},
	methods: {
		onSlideStart(slide) {
			this.sliding = true;
		},
		onSlideEnd(slide) {
			this.sliding = false;
		},
	},
};
</script>
<style lang="scss">
.c-container
{
	
	.slideimg
	{
		

		img {
			background-size: cover!important;
			background-position: center;;
			height: 92vh;
		}

		&::after
		{
			content: "";
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba(0, 0, 0, 0.5);

		}
	}
}


h2
{
	color: #b6977b;
	position: absolute;
	margin-top: -60%;
	float: left;
	margin-left: -30px;
	padding-bottom: 3px;


	body
	{
		-ms-overflow-style: none;
		/* for Internet Explorer, Edge */
		scrollbar-width: none;
		/* for Firefox */
		overflow-y: scroll;
		z-index: 0;
	}

	body::-webkit-scrollbar
	{
		display: none;
		/* for Chrome, Safari, and Opera */
	}



}

@media (min-width: 300px) and (max-width: 700px)
{
	h2
	{
		margin-top: -300px;
	}
}
</style>
