export function setLogin(state, payload) {
    if (payload.status === 200) {
        state.login = payload
        state.isLogged = true
    } else {
        state.isLogged = false
    }
}

export function setSignout(state, payload) {
    if (payload.status === 200) {
        state.isLogged = false
    }
}
