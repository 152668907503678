export function setPassengersDetails(state, payload) {
    state.passengers.push(payload)
}

export function removePassengersDetails(state, payload) {
    state.passengers.splice(payload, 1)
}

export function setPsgEmpty(state) {
    state.passengers = []
}