<template>
  <div class="aboutWrap">
    <div class="container">
      <div class="row" style="margin: 5% 0">
        <div class="col-md-12" style="display: flex; justify-content: center">
          <!--          <img class="icons" src="../assets/aboutus.svg" />-->
          <h1>ABOUT US</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 para">
          <p>
            We at Desert Link Tourism LLC are fully committed to providing
            memorable travel experiences to both our local and international
            customers. From Corporate Travel assistance to being your weekend
            getaway or a family vacation guide, we offer it all.
          </p>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-6 col-lg-6 para">
            <p>
              As a globally spread Destination Management Company (DMC) with
              three decades of experience in the travel industry, we are proud
              to expand our services to Global Travelers. Our team strives to
              offer an unforgettable travel experience to our esteemed
              customers. Our scope of services extends to numerous local
              attractions at compatible rates.
            </p>
          </div>
          <div class="col">
            <div class="card">
              <div class="card-header" style="text-align: center">CONTACT</div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12" style="text-align: center">
                    CONTACT NUMBER: +971 50 500 3723 / +971 58 591 3723
                  </div>
                  <div class="col-md-12" style="text-align: center">
                    EMAIL: UMAIRS@DESERTLINKTOURS.COM
                  </div>
                  <div class="col-md-12" style="text-align: center">
                    ADDRESS: OFFICE 901, ONTARIO TOWER, BUSINESS BAY, BURJ
                    KHALIFA DISTRICT, DUBAI, U.A.E.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Carousel from "../components/Carousel.vue";
import FooterHome from "../components/FooterHome.vue";

export default {
  name: "Home",
  components: {
    Navbar,
    Carousel,
    FooterHome,
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.aboutWrap {
  font-family: "Open Sans", sans-serif;
  background: url("../assets/backgroundColor.png");

  p {
    text-align: justify;
  }

  .icons {
    width: 32px;
    height: 32px;
    margin: 0 0 0 24px;
    position: relative;
    right: -18%;
    top: 11.5%;
    opacity: 0.8;
  }

  .line {
    height: 1px;
    width: 25%;
    background: #ff6f00;
    position: absolute;
    top: 24%;
    left: 42%;
  }

  .para {
    margin: 0 0 40px 0 !important;
    line-height: 3rem;
    // border: 1px solid red;
  }

  .card-container {
    // position: relative !important;
    // border: 1px solid red;
    // padding: 20px 10px;
    // padding: 10px 0;
    // position: absolute;
    // right: 0;
    border: 1px solid red;
  }

  .card {
    -webkit-box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
    -moz-box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
    box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);

    .card-header {
      background: #212121;
      color: white;
    }

    .card-body {
      font-weight: 300;
      padding: 20px 10px;
    }
  }
}

//   p {
//     text-align: justify;
//   }

//   h1 {
//     font-weight: 500;
//     color: #212121;
//   }

//   .icons {
//     width: 32px;
//     height: 32px;
//     margin: 0 0 0 24px;
//     position: absolute;
//     // right: 40%;
//     // top: 10%;
//     opacity: 0.8;
//     // display: inline-flexbox;
//   }

//   .line {
//     height: 1px;
//     width: 25%;
//     background: #ff6f00;
//     position: absolute;
//     // opacity: 0.3;
//     // top: 18%;
//     // left: 40%;
//   }

//   .para {
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-end !important;
//   }

// .card {
//   -webkit-box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
//   -moz-box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
//   box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
// }

// .card-header {
//   background: #212121;
//   color: white;
// }

// .card-body {
//   font-weight: 300;
// }
// }

// // @media (min-width: 300px) and (max-width: 700px){

// //   p{
// //     margin-top: 10%;
// //   }
// // }
</style>
